import { AppState } from '../app.state';

export const assetData = (state: AppState) => state.Asset.assetData;

export const loginLoader = (state: AppState) => state.Asset.loginLoader;

export const loginError = (state: AppState) => state.Asset.loginFail;

export const logoUrl = (state: AppState) => state.Asset.logoUrl;

export const getWallpapers = (state: AppState) => state?.Asset?.assetData?.[sessionStorage.getItem('lPin')]?.['wallpaper'];

